// フォントサイズ px rem 変換
@mixin font-size($size, $base: 16) {
  font-size: ($size / $base) + rem;
}

// Material Design Icons 設定
@mixin icon($code, $size: null, $color: null) {
  content: $code;
  font-family: Material Design Icons;
  @if $size {
    @include font-size($size);
  }
  @if $color {
    color: $color;
  }
}

