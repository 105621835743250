// 表示・非表示 {
.only {
  &--pc {
    @include mq('sp') {
      display: none;
    }
    @include mq('tb') {
      display: none;
    }
  }
  &--tb {
    @include mq('pc') {
      display: none;
    }
    @include mq('sp') {
      display: none;
    }
  }
  &--sp {
    @include mq('pc') {
      display: none;
    }
    @include mq('tb') {
      display: none;
    }
  }
}




// 強調文字
.emphasis {
  font-weight: 700;
}

// 補足文字
.supplement {
  color: #999;
}

// pdf アイコン
.pdf {
  &::after {
    content: "";
    display: inline-block;
    margin-left: 4px;
    margin-top: -2px;
    margin-bottom: -3px;
    vertical-align: -2px;
    color: #D25252;
    font-size: 1.125em;
    @include icon("\F226");
  }
}

// 外部リンク
.blank {
  &::after {
    content: "";
    display: inline-block;
    margin-left: 4px;
    vertical-align: -1px;
    color: #999;
    @include font-size(16);
    @include icon("\F137");
  }
}

// 挿入図
.inset {
  padding: 10px;
  text-align: center;
  figcaption {
    margin-bottom: 1rem;
    line-height: 1.4;
    @include font-size(14);
  }
  &--w10 { width: 10%; }
  &--w20 { width: 20%; }
  &--w30 { width: 30%; }
  &--w40 { width: 40%; }
  &--w50 { width: 50%; }
  &--w60 { width: 60%; }
  &--w70 { width: 70%; }
  &--w80 { width: 80%; }
  &--w90 { width: 90%; }
  &--w100 { width: 100%; }
}

// 文字位置
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

// 画像位置
.img-left {
  float: left;
  margin: 0 20px 10px 0;
}
.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

// 幅指定
.w5 { width: 5%; }
.w10 { width: 10%; }
.w15 { width: 15%; }
.w20 { width: 20%; }
.w25 { width: 25%; }
.w30 { width: 30%; }
.w35 { width: 35%; }
.w40 { width: 40%; }
.w45 { width: 45%; }
.w50 { width: 50%; }
.w55 { width: 55%; }
.w60 { width: 60%; }
.w65 { width: 65%; }
.w70 { width: 70%; }
.w75 { width: 75%; }
.w80 { width: 80%; }
.w85 { width: 85%; }
.w90 { width: 90%; }
.w95 { width: 95%; }
.w100 { width: 100%; }
