// 六角形 作成
@mixin hexagon($type, $width, $height, $bg-color, $hover) {
  position: relative;
  width: $width; 
  height: $height;
  background-color: $bg-color;
  margin: ($height/2) 0;
  z-index: 1;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    border-left: ($width/2) solid transparent;
    border-right: ($width/2) solid transparent;
    z-index: -1;
  }
  &::before {
    bottom: 100%;
    border-bottom: ($height/2) solid $bg-color;
  }
  &::after {
    top: 100%;
    width: 0;
    border-top: ($height/2) solid $bg-color;
  }
  @if $type == btn {
    &:hover {
      background-color: $hover;
      &::before {
        border-bottom: ($height/2) solid $hover;
      }
      &::after {
        border-top: ($height/2) solid $hover;
      }
    }
  }
}

