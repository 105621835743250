// トップページ
.top {

  // ヘッダ
  .header {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    background-color: #FFF;
    transition: all 0.3s;
    z-index: 1000;
    @include mq('pc') {
      height: 120px;
      padding: 0 20px;
    }
    @include mq('tb') {
      height: 90px;
      padding: 0 20px;
    }
    @include mq('sp') {
      height: 80px;
      padding: 0 10px;
    }
    &.mini {
      height: 80px;
      background-color: rgba($color: #FFF, $alpha: 0.95);
      .sub-title {
        @include font-size(12);
      }
      h1 {
        @include mq('pc') {
          @include font-size(20);
        }
        @include mq('tb') {
          @include font-size(20);
        }
        @include mq('sp') {
          @include font-size(16);
        }
      }
    }

    // サイトタイトル
    &__title {
      .sub-title {
        display: block;
        margin-bottom: 8px;
        font-weight: 700;
        @include mq('tb') {
          @include font-size(14);
        }
        @include mq('sp') {
          @include font-size(12);
        }
      }
      h1 {
        font-weight: 900;
        @include mq('pc') {
          @include font-size(28);
        }
        @include mq('tb') {
          @include font-size(24);
        }
        @include mq('sp') {
          @include font-size(16);
        }
      }
    }

    // メニュー
    &__navi {
      ul {
        display: flex;
        justify-content: flex-end;
        li {
          margin: 0 1vw;
          white-space: nowrap;
          a {
            font-weight: 900;
          }
        }
        .mail {
          a {
            display: flex;
            align-items: flex-start;
          }
          .mdi {
            margin-right: 4px;
            margin-top: -1px;
            @include font-size(20);
          }
        }
        .tel {
          position: relative;
          font-weight: 700;
          @include font-size(20);
          a {
            span {
              margin-right: 4px;
            }
          }
          .reception {
            position: absolute;
            @include mq('pc') {
              top: 2.5em;
              left: 2.5em;
            }
            @include mq('tb') {
              top: 4.5em;
              left: 3.5em;
            }
            @include mq('sp') {
              top: 4.1em;
              left: 4.1em;
            }
            font-weight: 100;
            color: #999;
            @include font-size(10);
          }
        }
      }
      @include mq('pc') {
        .sp-menu {
          display: none;
        }
      }
      @include mq('tb') {
        ul {
          display: none;
          position: absolute;
          top: 80px;
          left: 0;
          width: 100%;
          padding-bottom: 30px;
          background-color: #FFF;
          li a {
            display: block;
            padding: 15px 10px;
          }
        }
        // メニュー：スマホ
        .sp-menu {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          width: 60px;
          height: 60px;
          border-radius:3px;
          background-color: #333;
          color: #FFF;
          cursor: pointer;
          .mdi {
            display: flex;
            @include font-size(26);
          }
          .label {
            display: flex;
            @include font-size(10);
          }
        }
      }
      @include mq('sp') {
        ul {
          display: none;
          position: absolute;
          top: 80px;
          left: 0;
          width: 100%;
          padding-bottom: 30px;
          background-color: #FFF;
          li a {
            display: block;
            padding: 15px 10px;
          }
        }
        // メニュー：スマホ
        .sp-menu {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          width: 60px;
          height: 60px;
          border-radius:3px;
          // text-align: center;
          background-color: #333;
          color: #FFF;
          .mdi {
            display: flex;
            @include font-size(26);
          }
          .label {
            display: flex;
            @include font-size(10);
          }
        }
      }
    }
  }

  // コンテンツ
  .main {
    overflow: hidden;
    // ビジュアル
    &__visual {
      position: relative;
      display: flex;
      @include mq('pc') {
        height: 960px;
        padding-top: 120px;
      }
      @include mq('tb') {
        height: 710px;
        padding-top: 80px;
      }
      @include mq('sp') {
        flex-direction: column;
        // padding-top: 80px;
        padding-top: 300px;
        background: url("/img/photo/gyosei-bg.jpg") no-repeat center top;
      }
      @include mq('sp-tb') {
        flex-direction: row;
        flex-wrap: wrap;
      }
      // 共通
      &--gyosei,
      &--shigikai {
        display: flex;
        flex-direction: column;
        @include mq('pc') {
          width: 50%;
          padding: 100px 0 0 0;
          .title {
            position: relative;
            margin: 0 auto 50px auto;
            color: #FFF;
            font-weight: 900;
            @include font-size(40);
            h2 {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
              width: 500px;
              height: 100px;
            }
          }
          h3 {
            width: 60%;
            margin: 0 auto;
            margin-bottom: 30px;
            line-height: 1.5;
            text-align: center;
            font-weight: 900;
            @include font-size(30);
          }
          p {
            margin-bottom: 40px;
            text-align: center;
            font-weight: 700;
            @include font-size(20);
          }
          .btn__more {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: space-between;
            z-index: 100;
            @include hexagon('btn', 280px, 160px, #FFF7EC, #FFE8C9);
            .label {
              display: block;
              text-align: center;
              line-height: 1.5;
              font-weight: 700;
              @include font-size(26);
            }
            .mdi {
              position: absolute;
              bottom: -20px;
              width: 100%;
              text-align: center;
              z-index: 1;
              @include font-size(30);
            }
          }
        }
        @include mq('tb') {
          width: 50%;
          padding: 60px 0 0 0;
          .title {
            position: relative;
            margin: 0 auto 40px auto;
            color: #FFF;
            font-weight: 900;
            @include font-size(30);
            h2 {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
              width: 360px;
              height: 80px;
            }
          }
          h3 {
            // width: 60%;
            width: 340px;
            margin: 0 auto;
            line-height: 1.5;
            text-align: center;
            font-weight: 900;
            @include font-size(24);
          }
          p {
            position: relative;
            z-index: 100;
            width: 280px;
            padding: 0 30px;
            // margin: 0 2vw 20px auto;
            text-align: center;
            font-weight: 700;
            @include font-size(20);
          }
          .btn__more {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: space-between;
            z-index: 100;
            @include hexagon('btn', 210px, 120px, #FFF7EC, #FFE8C9);
            .label {
              display: block;
              text-align: center;
              line-height: 1.5;
              font-weight: 700;
              @include font-size(20);
            }
            .mdi {
              position: absolute;
              bottom: -20px;
              width: 100%;
              text-align: center;
              z-index: 1;
              @include font-size(30);
            }
          }
        }
        @include mq('sp') {
          margin: 0 10px;
          margin-bottom: 10px;
          padding: 15px 10px;
          text-align: center;
          background-color: #FFF;
          z-index: 10;
          h2 {
            margin-bottom: 10px;
            font-weight: 700;
            @include font-size(24);
          }
          h3 {
            line-height: 1.5;
          }
          svg {
            display: none;
          }
          .btn__more {
            display: none;
          }
        }
      }
      // 行政書士
      &--gyosei {
        @include mq('pc') {
          background: url("/img/photo/gyosei-bg.jpg") no-repeat center top;
          background-size: 100% auto;
          padding-right: 5%;
          box-sizing: border-box;
          // h3 {
          //   margin-bottom: 30px;
          // }
          // p {
          //   margin-bottom: 40px;
          //   text-align: center;
          //   font-weight: 700;
          //   @include font-size(20);
          // }
          .btn__more {
            span {
              color: map-get($colors, 'gyosei');
            }
          }
        }
        @include mq('tb') {
          background: url("/img/photo/gyosei-bg.jpg") no-repeat center top;
          background-size: 100% auto;
          .title {
            margin: 0 auto 40px 2vw;
          }
          h3 {
            margin: 0 auto 20px 2vw;
            // width: 300px;
            // margin: 0 2vw 20px auto;
          }
          p {
            margin: 0 auto 20px 2vw;
          }
          .btn__more {
            margin-left: 10%;
            margin-right: auto;
            span {
              color: map-get($colors, 'gyosei');
            }
          }
        }
        @include mq('sp') {
          border: 1px solid map-get($colors, 'gyosei');
          h2 {
            color: map-get($colors, 'gyosei');
          }
        }
        @include mq('sp-tb') {
          width: calc(50% - 11px);
          margin: 0 1px 0 10px;
          box-sizing: border-box;
        }
      }
      // 市議会議員
      &--shigikai {
        @include mq('pc') {
          background: url("/img/photo/shigikai-bg.jpg") no-repeat center top;
          background-size: 100% auto;
          padding-left: 5%;
          box-sizing: border-box;
          // h3 {
          //   margin-bottom: 30px;
          // }
          // p {
          //   margin-bottom: 40px;
          //   text-align: center;
          //   font-weight: 700;
          //   @include font-size(20);
          // }
          .btn__more {
            span {
              color: map-get($colors, 'shigikai');
            }
          }
        }
        @include mq('tb') {
          background: url("/img/photo/shigikai-bg.jpg") no-repeat center top;
          background-size: 100% auto;
          .title {
            margin: 0 2vw 20px auto;
          }
          h3 {
            // width: 300px;
            margin: 0 2vw 20px auto;
          }
          p {
            // position: relative;
            // z-index: 100;
            // width: 300px;
            margin: 0 2vw 20px auto;
            // text-align: center;
            // font-weight: 700;
            // @include font-size(20);
          }
          .btn__more {
            margin-left: auto;
            margin-right: 10%;
            span {
              color: map-get($colors, 'shigikai');
            }
          }
        }
        @include mq('sp') {
          border: 1px solid map-get($colors, 'shigikai');
          h2 {
            color: map-get($colors, 'shigikai');
          }
        }
        @include mq('sp-tb') {
          width: calc(50% - 11px);
          margin: 0 10px 0 1px;
          box-sizing: border-box;
        }
      }
      // 和田先生名前
      .wada-hideyuki {
        position: absolute;
        z-index: 50;
        @include mq('pc') {
          bottom: 100px;
          left: 45%;
        }
        @include mq('tb') {
          bottom: 60px;
          left: 40%;
          img {
            width: 80%;
          }
        }
        @include mq('sp') {
          top: 180px;
          left: 20px;
        }
      }
      // 和田先生
      &::before {
        content: "";
        position: absolute;
        display: block;
        @include mq('pc') {
          bottom: 40px;
          left: calc(50% - 18%);
          width: calc(20% + 260px);
          height: 100vh;
          background: url("/img/photo/wada-hideyuki.png") no-repeat center bottom;
          background-size: contain;
        }
        @include mq('tb') {
          bottom: 8vw;
          left: calc(50% - 18%);
          width: calc(25% + 100px);
          height: 65vw;
          background: url("/img/photo/wada-hideyuki.png") no-repeat center bottom;
          background-size: contain;
        }
        @include mq('sp') {
          top: 100px;
          right: 10px;
          width: 45%;
          height: 100%;
          background: url("/img/photo/wada-hideyuki.png") no-repeat center top;
          background-size: 100% auto;
        }
      }
    }

    // タブ切り替え：スマホ
    .tab {
      @include mq('sp') {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        // 共通
        &--gyosei,
        &--shigikai {
          width: 100%;
          margin: 0 10px;
          padding: 18px 0;
          text-align: center;
          font-weight: 700;
          background-color: #FFF;
          cursor: pointer;
        }
        // 行政書士
        &--gyosei {
          border: 1px solid map-get($colors, 'gyosei');
          border-bottom: none;
          color: map-get($colors, 'gyosei');
          &.active {
            color: #FFF;
            background-color: map-get($colors, 'gyosei');
          }
        }
        // 市議会議員
        &--shigikai {
          border: 1px solid map-get($colors, 'shigikai');
          border-bottom: none;
          color: map-get($colors, 'shigikai');
          &.active {
            color: #FFF;
            background-color: map-get($colors, 'shigikai');
          }
        }
      }
      @include mq('pc') {
        display: none;
      }
      @include mq('tb') {
        display: none;
      }
      @include mq('sp-tb') {
        width: 100%;
      }
    }

    // ビジュアルの帯
    .visual-band {
      @include mq('pc') {
        position: relative;
        // 市議会議員
        &::before {
          content: "";
          position: absolute;
          top: -30vw;
          right: -50vw;
          display: block;
          width: 100vw;
          height: 1000px;
          margin: 0 auto;
          transform: rotate(-30deg);
          background-color: map-get($colors, 'shigikai');
        }
        // 行政書士
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: -25vw;
          display: block;
          width: 150vw;
          height: 700px;
          margin: 0 auto;
          transform: rotate(30deg);
          background-color: map-get($colors, 'gyosei');
        }
      }
      @include mq('tb') {
        position: relative;
        // 市議会議員
        &::before {
          content: "";
          position: absolute;
          top: -30vw;
          right: -50vw;
          display: block;
          width: 100vw;
          height: 1000px;
          margin: 0 auto;
          transform: rotate(-30deg);
          background-color: map-get($colors, 'shigikai');
        }
        // 行政書士
        &::after {
          content: "";
          position: absolute;
          top: -1vw;
          left: -40vw;
          display: block;
          width: 190vw;
          height: 700px;
          margin: 0 auto;
          transform: rotate(30deg);
          background-color: map-get($colors, 'gyosei');
        }
      }
      @include mq('sp') {
        display: none;
      }
    }

    // 行政書士コンテンツ
    .gyosei {
      position: relative;
      padding-top: 50px;
      text-align: center;
      @include mq('sp') {
        background-color: map-get($colors, 'gyosei');
      }
      // 紹介文
      h2 {
        margin-bottom: 0.8em;
        font-weight: 900;
        color: #FFF;
        @include mq('pc') {
          @include font-size(70);
        }
        @include mq('tb') {
          @include font-size(70);
        }
        @include mq('sp') {
          @include font-size(30);
        }
      }
      &__intro {
        h3 {
          margin-bottom: 1em;
          font-weight: 700;
          color: #FFF;
          @include mq('pc') {
            @include font-size(26);
          }
          @include mq('tb') {
            @include font-size(26);
          }
        }
        p {
          margin: 0 auto;
          color: #FFF;
          line-height: 1.5;
          @include mq('pc') {
            width: 860px;
            margin-bottom: 80px;
            @include font-size(18);
          }
          @include mq('tb') {
            width: 80%;
            margin-bottom: 80px;
            @include font-size(18);
          }
          @include mq('sp') {
            margin-bottom: 30px;
            padding: 0 10px;
          }
        }
      }
      // 業務案内
      &__info {
        @include mq('sp') {
          margin: 0 10px;
          padding-top: 40px;
          background-color: #FFF;
        }
        h3 {
          font-weight: 700;
          @include mq('pc') {
            margin-bottom: 0.5em;
            color: #FFF;
            @include font-size(60);
          }
          @include mq('tb') {
            margin-bottom: 0.5em;
            color: #FFF;
            @include font-size(60);
          }
          @include mq('sp') {
            margin-bottom: 40px;
            @include font-size(40);
            color: map-get($colors, 'gyosei');
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          @include mq('pc') {
            padding-top: 85px;
          }
          @include mq('tb') {
            padding-top: 85px;
          }
          @include mq('sp-tb') {
            padding: 0 1px;
          }
          li {
            position: relative;
            @include mq('pc') {
              width: 300px;
              height: 346px;
              margin-top: -85px;
            }
            @include mq('tb') {
              width: 300px;
              height: 346px;
              margin-top: -85px;
            }
            @include mq('sp') {
              padding: 3px 10px;
            }
            @include mq('sp-tb') {
              width: 50%;
              padding: 1px;
            }
            a {
              position: relative;
              display: flex;
              flex-direction: column;
              @include mq('pc') {
                @include hexagon('btn', 280px, 160px, #FFF7EC, #FFE8C9);
              }
              @include mq('tb') {
                height: 100%;
                @include hexagon('btn', 280px, 160px, #FFF7EC, #FFE8C9);
              }
              @include mq('sp') {
                height: 100%;
                padding: 20px 10px;
                border: 1px solid map-get($colors, 'gyosei');
                background-color: #FFF7EC;
              }
              h4 {
                margin-bottom: 0.7em;
                padding: 0 20px;
                font-weight: 700;
                line-height: 1.3;
                @include font-size(20);
                color: map-get($colors, 'gyosei');
              }
              p {
                line-height: 1.5;
                @include font-size(14);
                @include mq('pc') {
                  padding: 0 20px;
                }
                @include mq('tb') {
                  padding: 0 20px;
                }
                @include mq('sp') {
                  margin-bottom: 10px;
                  padding: 0 10px;
                }
              }
              .mdi {
                color: #999;
                @include mq('pc') {
                  position: absolute;
                  bottom: -50px;
                  left: 50%;
                  margin-left: -7px;
                  z-index: 1;
                }
                @include mq('tb') {
                  position: absolute;
                  bottom: -50px;
                  left: 50%;
                  margin-left: -7px;
                  z-index: 1;
                }
              }
            }
          }
        }
        .other {
          font-weight: 700;
          @include mq('pc') {
            @include font-size(20);
          }
          @include mq('tb') {
            @include font-size(20);
          }
          @include mq('sp') {
            padding: 30px 10px;
          }
        }
      }
      // お問い合わせ
      &__contact {
        margin-bottom: 50px;
        background: url("/img/photo/gyosei-contact-bg.jpg") no-repeat bottom center;
        @include mq('pc') {
          height: 470px;
          margin-top: 50px;
          background-size: 100% 410px;
        }
        @include mq('tb') {
          height: 470px;
          margin-top: 50px;
          background-size: 100% 410px;
        }
        @include mq('sp') {
          margin-top: 10px;
          padding: 50px 10px;
        }
        &-inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          box-sizing: border-box;
          margin: 0 auto;
          background-color: map-get($colors, 'gyosei');
          @include mq('pc') {
            width: 860px;
            padding: 30px 60px 40px 60px;
          }
          @include mq('tb') {
            width: 860px;
            padding: 30px 60px 40px 60px;
          }
          @include mq('sp') {
            padding: 30px 20px;
          }
        }
        h3 {
          width: 100%;
          margin-bottom: 30px;
          font-weight: 700;
          color: #FFF;
          @include mq('pc') {
            @include font-size(28);
          }
          @include mq('tb') {
            @include font-size(28);
          }
          @include mq('sp') {
            @include font-size(22);
          }
        }
        &--tel {
          text-align: left;
          color: #FFF;
          @include mq('sp') {
            margin: 0 auto 10px auto;
          }
          p {
            font-weight: 700;
            @include mq('pc') {
              @include font-size(42);
            }
            @include mq('tb') {
              @include font-size(42);
            }
            @include mq('sp') {
              @include font-size(32);
            }
            .mdi {
              margin-right: 10px;
              @include mq('pc') {
                @include font-size(43);
              }
              @include mq('tb') {
                @include font-size(43);
              }
              @include mq('sp') {
                @include font-size(33);
              }
            }
          }
          .reception {
            @include mq('pc') {
              margin-left: 57px;
              @include font-size(20);
            }
            @include mq('tb') {
              margin-left: 57px;
              @include font-size(20);
            }
            @include mq('sp') {
              margin-left: 47px;
            }
          }
        }
        &--mail {
          padding-top: 15px;
          @include mq('sp') {
            margin: 0 auto;
          }
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 75px;
            border: 1px solid #FFF;
            text-align: center;
            line-height: 75px;
            font-weight: 500;
            color: #FFF;
            @include mq('pc') {
              width: 330px;
              @include font-size(20);
            }
            @include mq('tb') {
              width: 330px;
              @include font-size(20);
            }
            @include mq('sp') {
              padding: 10px 20px;
              @include font-size(16);
            }
            .mdi {
              margin-right: 10px;
              @include mq('pc') {
                @include font-size(43);
              }
              @include mq('tb') {
                @include font-size(43);
              }
              @include mq('sp') {
                @include font-size(26);
              }
            }
            &:hover {
              background-color: #FFF;
              color: map-get($colors, 'gyosei');
            }
          }
        }
      }
      // アクセス
      &__access {
        h3 {
          margin-bottom: 1em;
          font-weight: 700;
          @include mq('pc') {
            @include font-size(34);
          }
          @include mq('tb') {
            @include font-size(34);
          }
          @include mq('sp') {
            color: #FFF;
            @include font-size(24);
          }
        }
        p {
          @include mq('pc') {
            margin-bottom: 15px;
            @include font-size(18);
          }
          @include mq('tb') {
            margin-bottom: 15px;
            @include font-size(18);
          }
          @include mq('sp') {
            color: #FFF;
          }
        }
        .map {
          @include mq('sp') {
            padding: 10px;
          }
          iframe {
            @include mq('pc') {
              height: 460px;
            }
            @include mq('tb') {
              height: 460px;
            }
            @include mq('sp') {
              height: 230px;
            }
          }
        }
      }
    }
  }

  // 千曲市議会議員コンテンツ
  .shigikai {
    text-align: center;
    color: #FFF;
    @include mq('pc') {
      background: url("/img/photo/shigikai-intro-bg.jpg") no-repeat top center;
      padding-top: 150px;
      background-size: 100% auto;
    }
    @include mq('tb') {
      background: url("/img/photo/shigikai-intro-bg.jpg") no-repeat top center;
      padding-top: 150px;
      background-size: 100% auto;
    }
    @include mq('sp') {
      background: url("/img/photo/shigikai-intro-bg.jpg") map-get($colors, 'shigikai') no-repeat top center;
      padding-top: 60px;
      background-size: contain;
      // background-size: auto 100%;
    }
    // ビジュアルの帯
    .shigikai-band {
      @include mq('pc') {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 420px;
          left: -25vw;
          display: block;
          width: 150vw;
          height: 800px;
          margin: 0 auto;
          transform: rotate(-30deg);
          background-color: map-get($colors, 'shigikai');
        }
      }
      @include mq('tb') {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 280px;
          left: -40vw;
          display: block;
          width: 180vw;
          height: 800px;
          margin: 0 auto;
          transform: rotate(-30deg);
          background-color: map-get($colors, 'shigikai');
        }
      }
      @include mq('sp') {
        display: none;
      }
    }
    h2 {
      margin-bottom: 1em;
      font-weight: 900;
      @include mq('pc') {
        @include font-size(70);
      }
      @include mq('tb') {
        @include font-size(70);
      }
      @include mq('sp') {
        @include font-size(30);
      }
    }
    // キャッチ
    &__intro {
      position: relative;
      @include mq('pc') {
        margin-bottom: 100px;
      }
      @include mq('tb') {
        margin-bottom: 50px;
      }
      @include mq('sp') {
        margin-bottom: 50px;
      }
      h3 {
        margin-bottom: 1em;
        font-weight: 900;
        @include mq('pc') {
          @include font-size(50);
        }
        @include mq('tb') {
          @include font-size(50);
        }
        @include mq('sp') {
          @include font-size(20);
        }
      }
      p {
        font-weight: 500;
        @include mq('pc') {
          @include font-size(22);
        }
        @include mq('tb') {
          @include font-size(22);
        }
      }
    }
    // プロフィール
    &__profile {
      margin: 0 auto;
      @include mq('pc') {
        position: relative;
        width: 1200px;
        padding-top: 1040px;
      }
      @include mq('tb') {
        position: relative;
        width: 900px;
        margin: 0 auto;
        padding-top: 980px;
      }
      @include mq('sp') {
        padding: 10px;
        text-align: left;
        background-color: map-get($colors, 'shigikai');
      }
      // 共通
      .profile,
      .post,
      .career {
        display: flex;
        @include mq('pc') {
          @include hexagon('box', 440px, 255px, #FFF7EC, #FFF7EC);
        }
        @include mq('tb') {
          @include hexagon('box', 440px, 255px, #FFF7EC, #FFF7EC);
        }
        @include mq('sp') {
          flex-direction: column;
          padding: 10px 10px 30px 10px;
          background-color: #FFF;
        }
        h3 {
          font-weight: 700;
          white-space: nowrap;
          @include font-size(24);
          color: map-get($colors, 'shigikai');
          @include mq('pc') {
            margin-bottom: 30px;
          }
          @include mq('tb') {
            margin-bottom: 30px;
          }
          @include mq('sp') {
            margin-bottom: 0.7em;
          }
        }
        h4 {
          text-align: left;
          color: #333;
          @include font-size(20);
          @include mq('sp') {
            margin-bottom: 0.7em;
          }
          span {
            @include font-size(14);
          }
        }
        ul {
          list-style-type: disc;
          text-align: left;
          color: #333;
          @include font-size(14);
          li {
            margin-bottom: 0.5em;
            margin-left: 1.5em;
          }
        }
      }
      // 自己紹介
      .profile {
        flex-direction: column;
        @include mq('pc') {
          position: absolute;
          top: 0;
          left: 250px;
          h3 {
            margin-left: 5%;
          }
          h4,
          ul {
            margin: 0 15%;
            margin-bottom: 20px;
          }
        }
        @include mq('tb') {
          position: absolute;
          top: 0;
          left: 225px;
          h3 {
            margin-left: 5%;
          }
          h4,
          ul {
            margin: 0 15%;
            margin-bottom: 20px;
          }
        }
      }
      // 主な現職
      .post {
        @include mq('pc') {
          position: absolute;
          top: 0;
          left: 750px;
          align-items: center;
          h3 {
            margin-left: 5%;
          }
          ul {
            margin-left: 5%;
          }
        }
        @include mq('tb') {
          position: absolute;
          top: 390px;
          left: 450px;
          align-items: center;
          h3 {
            margin-left: 5%;
          }
          ul {
            margin-left: 5%;
          }
        }
      }
      // 主な経歴
      .career {
        @include mq('pc') {
          position: absolute;
          top: 430px;
          left: 0;
          align-items: center;
          h3 {
            margin-left: 5%;
            line-height: 1.5;
          }
          ul {
            margin-left: 5%;
          }
        }
        @include mq('tb') {
          position: absolute;
          top: 390px;
          left: 0;
          align-items: center;
          h3 {
            margin-left: 5%;
            line-height: 1.5;
          }
          ul {
            margin-left: 5%;
          }
        }
      }
      // 写真
      .photo {
        @include mq('pc') {
          position: absolute;
          top: 430px;
          left: 500px;
          display: flex;
        }
        @include mq('tb') {
          display: none;
        }
        @include mq('sp') {
          display: none;
        }
      }
    }
    // 政策
    &__policy {
      position: relative;
      margin: 0 auto;
      text-align: left;
      color: #333;
      z-index: 10;
      @include mq('pc') {
        padding-bottom: 190px;
        background: url("/img/photo/wada-hideyuki-zenshin.png") no-repeat bottom right;
      }
      @include mq('tb') {
        padding-bottom: 80px;
      }
      @include mq('sp') {
        padding-bottom: 40px;
        background-color: map-get($colors, 'shigikai');
      }
      .policy__head {
        display: flex;
        margin: 0 auto;
        line-height: 1.5;
        @include mq('pc') {
          align-items: flex-end;
          width: 1200px;
          margin-bottom: 80px;
        }
        @include mq('tb') {
          align-items: flex-end;
          width: 90%;
          margin-bottom: 80px;
        }
        @include mq('sp') {
          flex-direction: column;
          padding: 40px 10px;
          text-align: center;
        }
        h3 {
          font-weight: 900;
          @include mq('pc') {
            margin-right: 70px;
            @include font-size(60);
          }
          @include mq('tb') {
            margin-right: 70px;
            @include font-size(45);
          }
          @include mq('sp') {
            margin-bottom: 0.5em;
            color: #FFF;
            @include font-size(30);
          }
        }
        p {
          font-weight: 700;
          @include mq('pc') {
            @include font-size(30);
          }
          @include mq('tb') {
            @include font-size(24);
          }
          @include mq('sp') {
            color: #FFF;
            @include font-size(18);
          }
        }
      }
      .policy__main {
        display: flex;
        align-items: flex-start;
        margin: 0 auto;
        @include mq('pc') {
          width: 1200px;
        }
        @include mq('tb') {
          width: 90%;
        }
        @include mq('sp') {
          flex-direction: column;
          margin: 0 10px;
          padding: 20px 10px;
          background-color: #FFF;
        }
      }
      .policy__list {
        @include mq('pc') {
          margin-right: 60px;
        }
        @include mq('tb') {
          margin-right: 60px;
        }
        @include mq('sp') {
          width: 100%;
          background-color: #FFF;
        }
        h4 {
          margin-bottom: 20px;
          font-weight: 700;
          @include mq('pc') {
            @include font-size(46);
          }
          @include mq('tb') {
            @include font-size(38);
          }
          @include mq('sp') {
            text-align: center;
            @include font-size(30);
          }
        }
        ol {
          margin-left: 30px;
          counter-reset: li_count;
          > li {
            position: relative;
            margin-bottom: 40px;
            &:before {
              position: absolute;
              top: -8px;
              left: 0;
              margin-left: -1.8rem;
              font-weight: 700;
              @include font-size(32);
              counter-increment: li_count;
              content: counter(li_count);
              color: map-get($colors, 'shigikai');
            }
            h5 {
              margin-bottom: 0.5em;
              font-weight: 700;
              @include font-size(21);
            }
            ul {
              counter-reset: li_count;
              list-style-type: disc;
              li {
                margin-bottom: 0.5em;
                margin-left: 1.5em;
                @include font-size(14);
                @include mq('sp') {
                  line-height: 1.5;
                }
              }
            }
          }
        }
      }
      // 図
      .policy-map {
        @include mq('sp') {
          display: none;
        }
      }
      // 応援するボタン
      .assistant {
        @include mq('pc') {
          position: absolute;
          bottom: 220px;
          left: 50%;
        }
        @include mq('tb') {
          position: absolute;
          bottom: 130px;
          right: 5%;
        }
        @include mq('sp') {
          width: 100%;
        }
        a {
          display: flex;
          flex-direction: column;
          // justify-content: center;
          box-sizing: border-box;
          border-radius:3px;
          padding: 20px 0 15px 0;
          box-shadow: 8px 8px 6px 0px rgba(0,0,0,0.16);
          text-align: center;
          color: #FFF;
          background-color: map-get($colors, 'shigikai');
          @include mq('pc') {
            width: 390px;
          }
          @include mq('tb') {
            width: 390px;
          }
          &:hover {
            opacity: 0.75;
          }
          h5 {
            margin-bottom: 10px;
            font-weight: 700;
            @include font-size(23);
            .mdi {
              margin-top: 2px;
              margin-right: 7px;
              @include font-size(30);
            }
          }
          p {
            @include font-size(20);
            .mdi {
              margin-right: 8px;
              @include font-size(28);
            }
          }
        }
      }
    }
    // 活動報告
    &__activity {
      @include mq('pc') {
        margin-top: -50px;
        margin-bottom: 40px;
      }
      @include mq('tb') {
        margin-top: -50px;
        margin-bottom: 40px;
      }
      @include mq('sp') {
        padding: 0 10px 40px 10px;
        background-color: map-get($colors, 'shigikai');
      }
      h3 {
        margin-bottom: 0.6em;
        font-weight: 700;
        @include mq('pc') {
          color: #333;
          @include font-size(60);
        }
        @include mq('tb') {
          color: #333;
          @include font-size(60);
        }
        @include mq('sp') {
          color: #FFF;
          @include font-size(40);
        }
      }
      .activity__inner {
        padding: 40px;
        @include mq('pc') {
          background-color: map-get($colors, 'shigikai');
        }
        @include mq('tb') {
          background-color: map-get($colors, 'shigikai');
        }
        @include mq('sp') {
          background-color: #FFF;
        }
        ul {
          // display: flex;
          // justify-content: center;
          li {
            display: flex;
            flex-direction: column;
            padding: 0 15px;
            text-align: left;
            time {
              display: block;
              margin-bottom: 10px;
              @include mq('pc') {
                color: #FFF;
              }
              @include mq('tb') {
                color: #FFF;
              }
              @include mq('sp') {
                color: #333;
              }
            }
            .slide-img {
              overflow: hidden;
              max-height: 200px;
              margin-bottom: 10px;
            }
            img {
              width: 100%;
              @include mq('pc') {
                margin-bottom: 15px;
              }
              @include mq('tb') {
                margin-bottom: 15px;
              }
              @include mq('sp') {
                margin-bottom: 5px;
              }
            }
            h4 {
              @include mq('pc') {
                color: #FFF;
                @include font-size(20);
              }
              @include mq('tb') {
                color: #FFF;
                @include font-size(20);
              }
              @include mq('sp') {
                color: #333;
              }
            }
          }
        }
      }
    }
    // スライド
    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 40px;
      font-weight: 900;
      color: #FFF
    }
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 100%;
      background: #000;
      opacity: .5;
    }
    .swiper-pagination-bullet-active {
      background-color: #FFF;
      opacity: 1;
    }


    // アクセス
    &__access {
      @include mq('pc') {
        margin-bottom: 60px;
        color: #333;
      }
      @include mq('tb') {
        margin-bottom: 60px;
        color: #333;
      }
      @include mq('sp') {
        margin-bottom: 20px;
        color: #FFF;
        background-color: map-get($colors, 'shigikai');
      }
      h3 {
        margin-bottom: 0.5em;
        font-weight: 700;
        @include mq('pc') {
          @include font-size(34);
        }
        @include mq('tb') {
          @include font-size(34);
        }
        @include mq('sp') {
          @include font-size(24);
        }
      }
      p {
        @include mq('pc') {
          margin-bottom: 0.5em;
          @include font-size(18);
        }
        @include mq('tb') {
          margin-bottom: 0.5em;
          @include font-size(18);
        }
        @include mq('sp') {
          margin-bottom: 0.3em;
        }
      }
      .map {
        padding: 10px;
        iframe {
          @include mq('pc') {
            height: 460px;
          }
          @include mq('tb') {
            height: 460px;
          }
          @include mq('sp') {
            height: 230px;
          }
        }
      }
    }
  }

}

// フッター
.footer {
  margin-bottom: 20px;
  text-align: center;
  @include mq('pc') {
    @include font-size(17);
  }
  @include mq('tb') {
    @include font-size(17);
  }
  @include mq('sp') {
    padding-top: 20px;
    @include font-size(12);
  }
}