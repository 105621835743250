@charset "UTF-8";


@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:100,300,400,500,700,900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdn.materialdesignicons.com/4.7.95/css/materialdesignicons.min.css");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  height: 100%;
  text-rendering: optimizeLegibility;
  font-size: 16px;
}

body {
  color: #333;
  font-family: "Noto Sans JP", san-serif;
}

a {
  text-decoration: none;
  color: #333;
}

a:hover {
  text-decoration: none;
}

p {
  line-height: 1.6;
}

*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(204, 204, 204, 0.5);
}

*::-webkit-scrollbar-thumb {
  background-color: #333;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

@media screen and (max-width: 768px) {
  *, *:before, *:after {
    box-sizing: border-box;
  }
  img {
    max-width: 100%;
    height: auto;
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .only--pc {
    display: none;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .only--pc {
    display: none;
  }
}

@media screen and (min-width: 1124px) {
  .only--tb {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .only--tb {
    display: none;
  }
}

@media screen and (min-width: 1124px) {
  .only--sp {
    display: none;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .only--sp {
    display: none;
  }
}

.emphasis {
  font-weight: 700;
}

.supplement {
  color: #999;
}

.pdf::after {
  content: "";
  display: inline-block;
  margin-left: 4px;
  margin-top: -2px;
  margin-bottom: -3px;
  vertical-align: -2px;
  color: #D25252;
  font-size: 1.125em;
  content: "";
  font-family: Material Design Icons;
}

.blank::after {
  content: "";
  display: inline-block;
  margin-left: 4px;
  vertical-align: -1px;
  color: #999;
  font-size: 1rem;
  content: "";
  font-family: Material Design Icons;
}

.inset {
  padding: 10px;
  text-align: center;
}

.inset figcaption {
  margin-bottom: 1rem;
  line-height: 1.4;
  font-size: 0.875rem;
}

.inset--w10 {
  width: 10%;
}

.inset--w20 {
  width: 20%;
}

.inset--w30 {
  width: 30%;
}

.inset--w40 {
  width: 40%;
}

.inset--w50 {
  width: 50%;
}

.inset--w60 {
  width: 60%;
}

.inset--w70 {
  width: 70%;
}

.inset--w80 {
  width: 80%;
}

.inset--w90 {
  width: 90%;
}

.inset--w100 {
  width: 100%;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.img-left {
  float: left;
  margin: 0 20px 10px 0;
}

.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

.w5 {
  width: 5%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w35 {
  width: 35%;
}

.w40 {
  width: 40%;
}

.w45 {
  width: 45%;
}

.w50 {
  width: 50%;
}

.w55 {
  width: 55%;
}

.w60 {
  width: 60%;
}

.w65 {
  width: 65%;
}

.w70 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}

.w85 {
  width: 85%;
}

.w90 {
  width: 90%;
}

.w95 {
  width: 95%;
}

.w100 {
  width: 100%;
}

.top .header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #FFF;
  transition: all 0.3s;
  z-index: 1000;
}

@media screen and (min-width: 1124px) {
  .top .header {
    height: 120px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .header {
    height: 90px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .top .header {
    height: 80px;
    padding: 0 10px;
  }
}

.top .header.mini {
  height: 80px;
  background-color: rgba(255, 255, 255, 0.95);
}

.top .header.mini .sub-title {
  font-size: 0.75rem;
}

@media screen and (min-width: 1124px) {
  .top .header.mini h1 {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .header.mini h1 {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .top .header.mini h1 {
    font-size: 1rem;
  }
}

.top .header__title .sub-title {
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .header__title .sub-title {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 768px) {
  .top .header__title .sub-title {
    font-size: 0.75rem;
  }
}

.top .header__title h1 {
  font-weight: 900;
}

@media screen and (min-width: 1124px) {
  .top .header__title h1 {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .header__title h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .top .header__title h1 {
    font-size: 1rem;
  }
}

.top .header__navi ul {
  display: flex;
  justify-content: flex-end;
}

.top .header__navi ul li {
  margin: 0 1vw;
  white-space: nowrap;
}

.top .header__navi ul li a {
  font-weight: 900;
}

.top .header__navi ul .mail a {
  display: flex;
  align-items: flex-start;
}

.top .header__navi ul .mail .mdi {
  margin-right: 4px;
  margin-top: -1px;
  font-size: 1.25rem;
}

.top .header__navi ul .tel {
  position: relative;
  font-weight: 700;
  font-size: 1.25rem;
}

.top .header__navi ul .tel a span {
  margin-right: 4px;
}

.top .header__navi ul .tel .reception {
  position: absolute;
  font-weight: 100;
  color: #999;
  font-size: 0.625rem;
}

@media screen and (min-width: 1124px) {
  .top .header__navi ul .tel .reception {
    top: 2.5em;
    left: 2.5em;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .header__navi ul .tel .reception {
    top: 4.5em;
    left: 3.5em;
  }
}

@media screen and (max-width: 768px) {
  .top .header__navi ul .tel .reception {
    top: 4.1em;
    left: 4.1em;
  }
}

@media screen and (min-width: 1124px) {
  .top .header__navi .sp-menu {
    display: none;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .header__navi ul {
    display: none;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding-bottom: 30px;
    background-color: #FFF;
  }
  .top .header__navi ul li a {
    display: block;
    padding: 15px 10px;
  }
  .top .header__navi .sp-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 60px;
    height: 60px;
    border-radius: 3px;
    background-color: #333;
    color: #FFF;
    cursor: pointer;
  }
  .top .header__navi .sp-menu .mdi {
    display: flex;
    font-size: 1.625rem;
  }
  .top .header__navi .sp-menu .label {
    display: flex;
    font-size: 0.625rem;
  }
}

@media screen and (max-width: 768px) {
  .top .header__navi ul {
    display: none;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding-bottom: 30px;
    background-color: #FFF;
  }
  .top .header__navi ul li a {
    display: block;
    padding: 15px 10px;
  }
  .top .header__navi .sp-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 60px;
    height: 60px;
    border-radius: 3px;
    background-color: #333;
    color: #FFF;
  }
  .top .header__navi .sp-menu .mdi {
    display: flex;
    font-size: 1.625rem;
  }
  .top .header__navi .sp-menu .label {
    display: flex;
    font-size: 0.625rem;
  }
}

.top .main {
  overflow: hidden;
}

.top .main__visual {
  position: relative;
  display: flex;
}

@media screen and (min-width: 1124px) {
  .top .main__visual {
    height: 960px;
    padding-top: 120px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main__visual {
    height: 710px;
    padding-top: 80px;
  }
}

@media screen and (max-width: 768px) {
  .top .main__visual {
    flex-direction: column;
    padding-top: 300px;
    background: url("/img/photo/gyosei-bg.jpg") no-repeat center top;
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  .top .main__visual {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.top .main__visual--gyosei, .top .main__visual--shigikai {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1124px) {
  .top .main__visual--gyosei, .top .main__visual--shigikai {
    width: 50%;
    padding: 100px 0 0 0;
  }
  .top .main__visual--gyosei .title, .top .main__visual--shigikai .title {
    position: relative;
    margin: 0 auto 50px auto;
    color: #FFF;
    font-weight: 900;
    font-size: 2.5rem;
  }
  .top .main__visual--gyosei .title h2, .top .main__visual--shigikai .title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 500px;
    height: 100px;
  }
  .top .main__visual--gyosei h3, .top .main__visual--shigikai h3 {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 30px;
    line-height: 1.5;
    text-align: center;
    font-weight: 900;
    font-size: 1.875rem;
  }
  .top .main__visual--gyosei p, .top .main__visual--shigikai p {
    margin-bottom: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 1.25rem;
  }
  .top .main__visual--gyosei .btn__more, .top .main__visual--shigikai .btn__more {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    z-index: 100;
    position: relative;
    width: 280px;
    height: 160px;
    background-color: #FFF7EC;
    margin: 80px 0;
    z-index: 1;
  }
  .top .main__visual--gyosei .btn__more::before, .top .main__visual--gyosei .btn__more::after, .top .main__visual--shigikai .btn__more::before, .top .main__visual--shigikai .btn__more::after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 140px solid transparent;
    border-right: 140px solid transparent;
    z-index: -1;
  }
  .top .main__visual--gyosei .btn__more::before, .top .main__visual--shigikai .btn__more::before {
    bottom: 100%;
    border-bottom: 80px solid #FFF7EC;
  }
  .top .main__visual--gyosei .btn__more::after, .top .main__visual--shigikai .btn__more::after {
    top: 100%;
    width: 0;
    border-top: 80px solid #FFF7EC;
  }
  .top .main__visual--gyosei .btn__more:hover, .top .main__visual--shigikai .btn__more:hover {
    background-color: #FFE8C9;
  }
  .top .main__visual--gyosei .btn__more:hover::before, .top .main__visual--shigikai .btn__more:hover::before {
    border-bottom: 80px solid #FFE8C9;
  }
  .top .main__visual--gyosei .btn__more:hover::after, .top .main__visual--shigikai .btn__more:hover::after {
    border-top: 80px solid #FFE8C9;
  }
  .top .main__visual--gyosei .btn__more .label, .top .main__visual--shigikai .btn__more .label {
    display: block;
    text-align: center;
    line-height: 1.5;
    font-weight: 700;
    font-size: 1.625rem;
  }
  .top .main__visual--gyosei .btn__more .mdi, .top .main__visual--shigikai .btn__more .mdi {
    position: absolute;
    bottom: -20px;
    width: 100%;
    text-align: center;
    z-index: 1;
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main__visual--gyosei, .top .main__visual--shigikai {
    width: 50%;
    padding: 60px 0 0 0;
  }
  .top .main__visual--gyosei .title, .top .main__visual--shigikai .title {
    position: relative;
    margin: 0 auto 40px auto;
    color: #FFF;
    font-weight: 900;
    font-size: 1.875rem;
  }
  .top .main__visual--gyosei .title h2, .top .main__visual--shigikai .title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 360px;
    height: 80px;
  }
  .top .main__visual--gyosei h3, .top .main__visual--shigikai h3 {
    width: 340px;
    margin: 0 auto;
    line-height: 1.5;
    text-align: center;
    font-weight: 900;
    font-size: 1.5rem;
  }
  .top .main__visual--gyosei p, .top .main__visual--shigikai p {
    position: relative;
    z-index: 100;
    width: 280px;
    padding: 0 30px;
    text-align: center;
    font-weight: 700;
    font-size: 1.25rem;
  }
  .top .main__visual--gyosei .btn__more, .top .main__visual--shigikai .btn__more {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    z-index: 100;
    position: relative;
    width: 210px;
    height: 120px;
    background-color: #FFF7EC;
    margin: 60px 0;
    z-index: 1;
  }
  .top .main__visual--gyosei .btn__more::before, .top .main__visual--gyosei .btn__more::after, .top .main__visual--shigikai .btn__more::before, .top .main__visual--shigikai .btn__more::after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 105px solid transparent;
    border-right: 105px solid transparent;
    z-index: -1;
  }
  .top .main__visual--gyosei .btn__more::before, .top .main__visual--shigikai .btn__more::before {
    bottom: 100%;
    border-bottom: 60px solid #FFF7EC;
  }
  .top .main__visual--gyosei .btn__more::after, .top .main__visual--shigikai .btn__more::after {
    top: 100%;
    width: 0;
    border-top: 60px solid #FFF7EC;
  }
  .top .main__visual--gyosei .btn__more:hover, .top .main__visual--shigikai .btn__more:hover {
    background-color: #FFE8C9;
  }
  .top .main__visual--gyosei .btn__more:hover::before, .top .main__visual--shigikai .btn__more:hover::before {
    border-bottom: 60px solid #FFE8C9;
  }
  .top .main__visual--gyosei .btn__more:hover::after, .top .main__visual--shigikai .btn__more:hover::after {
    border-top: 60px solid #FFE8C9;
  }
  .top .main__visual--gyosei .btn__more .label, .top .main__visual--shigikai .btn__more .label {
    display: block;
    text-align: center;
    line-height: 1.5;
    font-weight: 700;
    font-size: 1.25rem;
  }
  .top .main__visual--gyosei .btn__more .mdi, .top .main__visual--shigikai .btn__more .mdi {
    position: absolute;
    bottom: -20px;
    width: 100%;
    text-align: center;
    z-index: 1;
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main__visual--gyosei, .top .main__visual--shigikai {
    margin: 0 10px;
    margin-bottom: 10px;
    padding: 15px 10px;
    text-align: center;
    background-color: #FFF;
    z-index: 10;
  }
  .top .main__visual--gyosei h2, .top .main__visual--shigikai h2 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 1.5rem;
  }
  .top .main__visual--gyosei h3, .top .main__visual--shigikai h3 {
    line-height: 1.5;
  }
  .top .main__visual--gyosei svg, .top .main__visual--shigikai svg {
    display: none;
  }
  .top .main__visual--gyosei .btn__more, .top .main__visual--shigikai .btn__more {
    display: none;
  }
}

@media screen and (min-width: 1124px) {
  .top .main__visual--gyosei {
    background: url("/img/photo/gyosei-bg.jpg") no-repeat center top;
    background-size: 100% auto;
    padding-right: 5%;
    box-sizing: border-box;
  }
  .top .main__visual--gyosei .btn__more span {
    color: #996154;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main__visual--gyosei {
    background: url("/img/photo/gyosei-bg.jpg") no-repeat center top;
    background-size: 100% auto;
  }
  .top .main__visual--gyosei .title {
    margin: 0 auto 40px 2vw;
  }
  .top .main__visual--gyosei h3 {
    margin: 0 auto 20px 2vw;
  }
  .top .main__visual--gyosei p {
    margin: 0 auto 20px 2vw;
  }
  .top .main__visual--gyosei .btn__more {
    margin-left: 10%;
    margin-right: auto;
  }
  .top .main__visual--gyosei .btn__more span {
    color: #996154;
  }
}

@media screen and (max-width: 768px) {
  .top .main__visual--gyosei {
    border: 1px solid #996154;
  }
  .top .main__visual--gyosei h2 {
    color: #996154;
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  .top .main__visual--gyosei {
    width: calc(50% - 11px);
    margin: 0 1px 0 10px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1124px) {
  .top .main__visual--shigikai {
    background: url("/img/photo/shigikai-bg.jpg") no-repeat center top;
    background-size: 100% auto;
    padding-left: 5%;
    box-sizing: border-box;
  }
  .top .main__visual--shigikai .btn__more span {
    color: #0087CB;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main__visual--shigikai {
    background: url("/img/photo/shigikai-bg.jpg") no-repeat center top;
    background-size: 100% auto;
  }
  .top .main__visual--shigikai .title {
    margin: 0 2vw 20px auto;
  }
  .top .main__visual--shigikai h3 {
    margin: 0 2vw 20px auto;
  }
  .top .main__visual--shigikai p {
    margin: 0 2vw 20px auto;
  }
  .top .main__visual--shigikai .btn__more {
    margin-left: auto;
    margin-right: 10%;
  }
  .top .main__visual--shigikai .btn__more span {
    color: #0087CB;
  }
}

@media screen and (max-width: 768px) {
  .top .main__visual--shigikai {
    border: 1px solid #0087CB;
  }
  .top .main__visual--shigikai h2 {
    color: #0087CB;
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  .top .main__visual--shigikai {
    width: calc(50% - 11px);
    margin: 0 10px 0 1px;
    box-sizing: border-box;
  }
}

.top .main__visual .wada-hideyuki {
  position: absolute;
  z-index: 50;
}

@media screen and (min-width: 1124px) {
  .top .main__visual .wada-hideyuki {
    bottom: 100px;
    left: 45%;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main__visual .wada-hideyuki {
    bottom: 60px;
    left: 40%;
  }
  .top .main__visual .wada-hideyuki img {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .top .main__visual .wada-hideyuki {
    top: 180px;
    left: 20px;
  }
}

.top .main__visual::before {
  content: "";
  position: absolute;
  display: block;
}

@media screen and (min-width: 1124px) {
  .top .main__visual::before {
    bottom: 40px;
    left: calc(50% - 18%);
    width: calc(20% + 260px);
    height: 100vh;
    background: url("/img/photo/wada-hideyuki.png") no-repeat center bottom;
    background-size: contain;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main__visual::before {
    bottom: 8vw;
    left: calc(50% - 18%);
    width: calc(25% + 100px);
    height: 65vw;
    background: url("/img/photo/wada-hideyuki.png") no-repeat center bottom;
    background-size: contain;
  }
}

@media screen and (max-width: 768px) {
  .top .main__visual::before {
    top: 100px;
    right: 10px;
    width: 45%;
    height: 100%;
    background: url("/img/photo/wada-hideyuki.png") no-repeat center top;
    background-size: 100% auto;
  }
}

@media screen and (max-width: 768px) {
  .top .main .tab {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .top .main .tab--gyosei, .top .main .tab--shigikai {
    width: 100%;
    margin: 0 10px;
    padding: 18px 0;
    text-align: center;
    font-weight: 700;
    background-color: #FFF;
    cursor: pointer;
  }
  .top .main .tab--gyosei {
    border: 1px solid #996154;
    border-bottom: none;
    color: #996154;
  }
  .top .main .tab--gyosei.active {
    color: #FFF;
    background-color: #996154;
  }
  .top .main .tab--shigikai {
    border: 1px solid #0087CB;
    border-bottom: none;
    color: #0087CB;
  }
  .top .main .tab--shigikai.active {
    color: #FFF;
    background-color: #0087CB;
  }
}

@media screen and (min-width: 1124px) {
  .top .main .tab {
    display: none;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .tab {
    display: none;
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  .top .main .tab {
    width: 100%;
  }
}

@media screen and (min-width: 1124px) {
  .top .main .visual-band {
    position: relative;
  }
  .top .main .visual-band::before {
    content: "";
    position: absolute;
    top: -30vw;
    right: -50vw;
    display: block;
    width: 100vw;
    height: 1000px;
    margin: 0 auto;
    transform: rotate(-30deg);
    background-color: #0087CB;
  }
  .top .main .visual-band::after {
    content: "";
    position: absolute;
    top: 0;
    left: -25vw;
    display: block;
    width: 150vw;
    height: 700px;
    margin: 0 auto;
    transform: rotate(30deg);
    background-color: #996154;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .visual-band {
    position: relative;
  }
  .top .main .visual-band::before {
    content: "";
    position: absolute;
    top: -30vw;
    right: -50vw;
    display: block;
    width: 100vw;
    height: 1000px;
    margin: 0 auto;
    transform: rotate(-30deg);
    background-color: #0087CB;
  }
  .top .main .visual-band::after {
    content: "";
    position: absolute;
    top: -1vw;
    left: -40vw;
    display: block;
    width: 190vw;
    height: 700px;
    margin: 0 auto;
    transform: rotate(30deg);
    background-color: #996154;
  }
}

@media screen and (max-width: 768px) {
  .top .main .visual-band {
    display: none;
  }
}

.top .main .gyosei {
  position: relative;
  padding-top: 50px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .top .main .gyosei {
    background-color: #996154;
  }
}

.top .main .gyosei h2 {
  margin-bottom: 0.8em;
  font-weight: 900;
  color: #FFF;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei h2 {
    font-size: 4.375rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei h2 {
    font-size: 4.375rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei h2 {
    font-size: 1.875rem;
  }
}

.top .main .gyosei__intro h3 {
  margin-bottom: 1em;
  font-weight: 700;
  color: #FFF;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__intro h3 {
    font-size: 1.625rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__intro h3 {
    font-size: 1.625rem;
  }
}

.top .main .gyosei__intro p {
  margin: 0 auto;
  color: #FFF;
  line-height: 1.5;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__intro p {
    width: 860px;
    margin-bottom: 80px;
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__intro p {
    width: 80%;
    margin-bottom: 80px;
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__intro p {
    margin-bottom: 30px;
    padding: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__info {
    margin: 0 10px;
    padding-top: 40px;
    background-color: #FFF;
  }
}

.top .main .gyosei__info h3 {
  font-weight: 700;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__info h3 {
    margin-bottom: 0.5em;
    color: #FFF;
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__info h3 {
    margin-bottom: 0.5em;
    color: #FFF;
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__info h3 {
    margin-bottom: 40px;
    font-size: 2.5rem;
    color: #996154;
  }
}

.top .main .gyosei__info ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__info ul {
    padding-top: 85px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__info ul {
    padding-top: 85px;
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  .top .main .gyosei__info ul {
    padding: 0 1px;
  }
}

.top .main .gyosei__info ul li {
  position: relative;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__info ul li {
    width: 300px;
    height: 346px;
    margin-top: -85px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__info ul li {
    width: 300px;
    height: 346px;
    margin-top: -85px;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__info ul li {
    padding: 3px 10px;
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  .top .main .gyosei__info ul li {
    width: 50%;
    padding: 1px;
  }
}

.top .main .gyosei__info ul li a {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__info ul li a {
    position: relative;
    width: 280px;
    height: 160px;
    background-color: #FFF7EC;
    margin: 80px 0;
    z-index: 1;
  }
  .top .main .gyosei__info ul li a::before, .top .main .gyosei__info ul li a::after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 140px solid transparent;
    border-right: 140px solid transparent;
    z-index: -1;
  }
  .top .main .gyosei__info ul li a::before {
    bottom: 100%;
    border-bottom: 80px solid #FFF7EC;
  }
  .top .main .gyosei__info ul li a::after {
    top: 100%;
    width: 0;
    border-top: 80px solid #FFF7EC;
  }
  .top .main .gyosei__info ul li a:hover {
    background-color: #FFE8C9;
  }
  .top .main .gyosei__info ul li a:hover::before {
    border-bottom: 80px solid #FFE8C9;
  }
  .top .main .gyosei__info ul li a:hover::after {
    border-top: 80px solid #FFE8C9;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__info ul li a {
    height: 100%;
    position: relative;
    width: 280px;
    height: 160px;
    background-color: #FFF7EC;
    margin: 80px 0;
    z-index: 1;
  }
  .top .main .gyosei__info ul li a::before, .top .main .gyosei__info ul li a::after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 140px solid transparent;
    border-right: 140px solid transparent;
    z-index: -1;
  }
  .top .main .gyosei__info ul li a::before {
    bottom: 100%;
    border-bottom: 80px solid #FFF7EC;
  }
  .top .main .gyosei__info ul li a::after {
    top: 100%;
    width: 0;
    border-top: 80px solid #FFF7EC;
  }
  .top .main .gyosei__info ul li a:hover {
    background-color: #FFE8C9;
  }
  .top .main .gyosei__info ul li a:hover::before {
    border-bottom: 80px solid #FFE8C9;
  }
  .top .main .gyosei__info ul li a:hover::after {
    border-top: 80px solid #FFE8C9;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__info ul li a {
    height: 100%;
    padding: 20px 10px;
    border: 1px solid #996154;
    background-color: #FFF7EC;
  }
}

.top .main .gyosei__info ul li a h4 {
  margin-bottom: 0.7em;
  padding: 0 20px;
  font-weight: 700;
  line-height: 1.3;
  font-size: 1.25rem;
  color: #996154;
}

.top .main .gyosei__info ul li a p {
  line-height: 1.5;
  font-size: 0.875rem;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__info ul li a p {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__info ul li a p {
    padding: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__info ul li a p {
    margin-bottom: 10px;
    padding: 0 10px;
  }
}

.top .main .gyosei__info ul li a .mdi {
  color: #999;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__info ul li a .mdi {
    position: absolute;
    bottom: -50px;
    left: 50%;
    margin-left: -7px;
    z-index: 1;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__info ul li a .mdi {
    position: absolute;
    bottom: -50px;
    left: 50%;
    margin-left: -7px;
    z-index: 1;
  }
}

.top .main .gyosei__info .other {
  font-weight: 700;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__info .other {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__info .other {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__info .other {
    padding: 30px 10px;
  }
}

.top .main .gyosei__contact {
  margin-bottom: 50px;
  background: url("/img/photo/gyosei-contact-bg.jpg") no-repeat bottom center;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__contact {
    height: 470px;
    margin-top: 50px;
    background-size: 100% 410px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__contact {
    height: 470px;
    margin-top: 50px;
    background-size: 100% 410px;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact {
    margin-top: 10px;
    padding: 50px 10px;
  }
}

.top .main .gyosei__contact-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #996154;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__contact-inner {
    width: 860px;
    padding: 30px 60px 40px 60px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__contact-inner {
    width: 860px;
    padding: 30px 60px 40px 60px;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact-inner {
    padding: 30px 20px;
  }
}

.top .main .gyosei__contact h3 {
  width: 100%;
  margin-bottom: 30px;
  font-weight: 700;
  color: #FFF;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__contact h3 {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__contact h3 {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact h3 {
    font-size: 1.375rem;
  }
}

.top .main .gyosei__contact--tel {
  text-align: left;
  color: #FFF;
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact--tel {
    margin: 0 auto 10px auto;
  }
}

.top .main .gyosei__contact--tel p {
  font-weight: 700;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__contact--tel p {
    font-size: 2.625rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__contact--tel p {
    font-size: 2.625rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact--tel p {
    font-size: 2rem;
  }
}

.top .main .gyosei__contact--tel p .mdi {
  margin-right: 10px;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__contact--tel p .mdi {
    font-size: 2.6875rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__contact--tel p .mdi {
    font-size: 2.6875rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact--tel p .mdi {
    font-size: 2.0625rem;
  }
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__contact--tel .reception {
    margin-left: 57px;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__contact--tel .reception {
    margin-left: 57px;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact--tel .reception {
    margin-left: 47px;
  }
}

.top .main .gyosei__contact--mail {
  padding-top: 15px;
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact--mail {
    margin: 0 auto;
  }
}

.top .main .gyosei__contact--mail a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  border: 1px solid #FFF;
  text-align: center;
  line-height: 75px;
  font-weight: 500;
  color: #FFF;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__contact--mail a {
    width: 330px;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__contact--mail a {
    width: 330px;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact--mail a {
    padding: 10px 20px;
    font-size: 1rem;
  }
}

.top .main .gyosei__contact--mail a .mdi {
  margin-right: 10px;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__contact--mail a .mdi {
    font-size: 2.6875rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__contact--mail a .mdi {
    font-size: 2.6875rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__contact--mail a .mdi {
    font-size: 1.625rem;
  }
}

.top .main .gyosei__contact--mail a:hover {
  background-color: #FFF;
  color: #996154;
}

.top .main .gyosei__access h3 {
  margin-bottom: 1em;
  font-weight: 700;
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__access h3 {
    font-size: 2.125rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__access h3 {
    font-size: 2.125rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__access h3 {
    color: #FFF;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__access p {
    margin-bottom: 15px;
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__access p {
    margin-bottom: 15px;
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__access p {
    color: #FFF;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__access .map {
    padding: 10px;
  }
}

@media screen and (min-width: 1124px) {
  .top .main .gyosei__access .map iframe {
    height: 460px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .main .gyosei__access .map iframe {
    height: 460px;
  }
}

@media screen and (max-width: 768px) {
  .top .main .gyosei__access .map iframe {
    height: 230px;
  }
}

.top .shigikai {
  text-align: center;
  color: #FFF;
}

@media screen and (min-width: 1124px) {
  .top .shigikai {
    background: url("/img/photo/shigikai-intro-bg.jpg") no-repeat top center;
    padding-top: 150px;
    background-size: 100% auto;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai {
    background: url("/img/photo/shigikai-intro-bg.jpg") no-repeat top center;
    padding-top: 150px;
    background-size: 100% auto;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai {
    background: url("/img/photo/shigikai-intro-bg.jpg") #0087CB no-repeat top center;
    padding-top: 60px;
    background-size: contain;
  }
}

@media screen and (min-width: 1124px) {
  .top .shigikai .shigikai-band {
    position: relative;
  }
  .top .shigikai .shigikai-band::before {
    content: "";
    position: absolute;
    top: 420px;
    left: -25vw;
    display: block;
    width: 150vw;
    height: 800px;
    margin: 0 auto;
    transform: rotate(-30deg);
    background-color: #0087CB;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai .shigikai-band {
    position: relative;
  }
  .top .shigikai .shigikai-band::before {
    content: "";
    position: absolute;
    top: 280px;
    left: -40vw;
    display: block;
    width: 180vw;
    height: 800px;
    margin: 0 auto;
    transform: rotate(-30deg);
    background-color: #0087CB;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai .shigikai-band {
    display: none;
  }
}

.top .shigikai h2 {
  margin-bottom: 1em;
  font-weight: 900;
}

@media screen and (min-width: 1124px) {
  .top .shigikai h2 {
    font-size: 4.375rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai h2 {
    font-size: 4.375rem;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai h2 {
    font-size: 1.875rem;
  }
}

.top .shigikai__intro {
  position: relative;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__intro {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__intro {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__intro {
    margin-bottom: 50px;
  }
}

.top .shigikai__intro h3 {
  margin-bottom: 1em;
  font-weight: 900;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__intro h3 {
    font-size: 3.125rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__intro h3 {
    font-size: 3.125rem;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__intro h3 {
    font-size: 1.25rem;
  }
}

.top .shigikai__intro p {
  font-weight: 500;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__intro p {
    font-size: 1.375rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__intro p {
    font-size: 1.375rem;
  }
}

.top .shigikai__profile {
  margin: 0 auto;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__profile {
    position: relative;
    width: 1200px;
    padding-top: 1040px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__profile {
    position: relative;
    width: 900px;
    margin: 0 auto;
    padding-top: 980px;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__profile {
    padding: 10px;
    text-align: left;
    background-color: #0087CB;
  }
}

.top .shigikai__profile .profile,
.top .shigikai__profile .post,
.top .shigikai__profile .career {
  display: flex;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__profile .profile,
  .top .shigikai__profile .post,
  .top .shigikai__profile .career {
    position: relative;
    width: 440px;
    height: 255px;
    background-color: #FFF7EC;
    margin: 127.5px 0;
    z-index: 1;
  }
  .top .shigikai__profile .profile::before, .top .shigikai__profile .profile::after,
  .top .shigikai__profile .post::before,
  .top .shigikai__profile .post::after,
  .top .shigikai__profile .career::before,
  .top .shigikai__profile .career::after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 220px solid transparent;
    border-right: 220px solid transparent;
    z-index: -1;
  }
  .top .shigikai__profile .profile::before,
  .top .shigikai__profile .post::before,
  .top .shigikai__profile .career::before {
    bottom: 100%;
    border-bottom: 127.5px solid #FFF7EC;
  }
  .top .shigikai__profile .profile::after,
  .top .shigikai__profile .post::after,
  .top .shigikai__profile .career::after {
    top: 100%;
    width: 0;
    border-top: 127.5px solid #FFF7EC;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__profile .profile,
  .top .shigikai__profile .post,
  .top .shigikai__profile .career {
    position: relative;
    width: 440px;
    height: 255px;
    background-color: #FFF7EC;
    margin: 127.5px 0;
    z-index: 1;
  }
  .top .shigikai__profile .profile::before, .top .shigikai__profile .profile::after,
  .top .shigikai__profile .post::before,
  .top .shigikai__profile .post::after,
  .top .shigikai__profile .career::before,
  .top .shigikai__profile .career::after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 220px solid transparent;
    border-right: 220px solid transparent;
    z-index: -1;
  }
  .top .shigikai__profile .profile::before,
  .top .shigikai__profile .post::before,
  .top .shigikai__profile .career::before {
    bottom: 100%;
    border-bottom: 127.5px solid #FFF7EC;
  }
  .top .shigikai__profile .profile::after,
  .top .shigikai__profile .post::after,
  .top .shigikai__profile .career::after {
    top: 100%;
    width: 0;
    border-top: 127.5px solid #FFF7EC;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__profile .profile,
  .top .shigikai__profile .post,
  .top .shigikai__profile .career {
    flex-direction: column;
    padding: 10px 10px 30px 10px;
    background-color: #FFF;
  }
}

.top .shigikai__profile .profile h3,
.top .shigikai__profile .post h3,
.top .shigikai__profile .career h3 {
  font-weight: 700;
  white-space: nowrap;
  font-size: 1.5rem;
  color: #0087CB;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__profile .profile h3,
  .top .shigikai__profile .post h3,
  .top .shigikai__profile .career h3 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__profile .profile h3,
  .top .shigikai__profile .post h3,
  .top .shigikai__profile .career h3 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__profile .profile h3,
  .top .shigikai__profile .post h3,
  .top .shigikai__profile .career h3 {
    margin-bottom: 0.7em;
  }
}

.top .shigikai__profile .profile h4,
.top .shigikai__profile .post h4,
.top .shigikai__profile .career h4 {
  text-align: left;
  color: #333;
  font-size: 1.25rem;
}

@media screen and (max-width: 768px) {
  .top .shigikai__profile .profile h4,
  .top .shigikai__profile .post h4,
  .top .shigikai__profile .career h4 {
    margin-bottom: 0.7em;
  }
}

.top .shigikai__profile .profile h4 span,
.top .shigikai__profile .post h4 span,
.top .shigikai__profile .career h4 span {
  font-size: 0.875rem;
}

.top .shigikai__profile .profile ul,
.top .shigikai__profile .post ul,
.top .shigikai__profile .career ul {
  list-style-type: disc;
  text-align: left;
  color: #333;
  font-size: 0.875rem;
}

.top .shigikai__profile .profile ul li,
.top .shigikai__profile .post ul li,
.top .shigikai__profile .career ul li {
  margin-bottom: 0.5em;
  margin-left: 1.5em;
}

.top .shigikai__profile .profile {
  flex-direction: column;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__profile .profile {
    position: absolute;
    top: 0;
    left: 250px;
  }
  .top .shigikai__profile .profile h3 {
    margin-left: 5%;
  }
  .top .shigikai__profile .profile h4,
  .top .shigikai__profile .profile ul {
    margin: 0 15%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__profile .profile {
    position: absolute;
    top: 0;
    left: 225px;
  }
  .top .shigikai__profile .profile h3 {
    margin-left: 5%;
  }
  .top .shigikai__profile .profile h4,
  .top .shigikai__profile .profile ul {
    margin: 0 15%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1124px) {
  .top .shigikai__profile .post {
    position: absolute;
    top: 0;
    left: 750px;
    align-items: center;
  }
  .top .shigikai__profile .post h3 {
    margin-left: 5%;
  }
  .top .shigikai__profile .post ul {
    margin-left: 5%;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__profile .post {
    position: absolute;
    top: 390px;
    left: 450px;
    align-items: center;
  }
  .top .shigikai__profile .post h3 {
    margin-left: 5%;
  }
  .top .shigikai__profile .post ul {
    margin-left: 5%;
  }
}

@media screen and (min-width: 1124px) {
  .top .shigikai__profile .career {
    position: absolute;
    top: 430px;
    left: 0;
    align-items: center;
  }
  .top .shigikai__profile .career h3 {
    margin-left: 5%;
    line-height: 1.5;
  }
  .top .shigikai__profile .career ul {
    margin-left: 5%;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__profile .career {
    position: absolute;
    top: 390px;
    left: 0;
    align-items: center;
  }
  .top .shigikai__profile .career h3 {
    margin-left: 5%;
    line-height: 1.5;
  }
  .top .shigikai__profile .career ul {
    margin-left: 5%;
  }
}

@media screen and (min-width: 1124px) {
  .top .shigikai__profile .photo {
    position: absolute;
    top: 430px;
    left: 500px;
    display: flex;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__profile .photo {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__profile .photo {
    display: none;
  }
}

.top .shigikai__policy {
  position: relative;
  margin: 0 auto;
  text-align: left;
  color: #333;
  z-index: 10;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__policy {
    padding-bottom: 190px;
    background: url("/img/photo/wada-hideyuki-zenshin.png") no-repeat bottom right;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__policy {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy {
    padding-bottom: 40px;
    background-color: #0087CB;
  }
}

.top .shigikai__policy .policy__head {
  display: flex;
  margin: 0 auto;
  line-height: 1.5;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__policy .policy__head {
    align-items: flex-end;
    width: 1200px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__policy .policy__head {
    align-items: flex-end;
    width: 90%;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy .policy__head {
    flex-direction: column;
    padding: 40px 10px;
    text-align: center;
  }
}

.top .shigikai__policy .policy__head h3 {
  font-weight: 900;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__policy .policy__head h3 {
    margin-right: 70px;
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__policy .policy__head h3 {
    margin-right: 70px;
    font-size: 2.8125rem;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy .policy__head h3 {
    margin-bottom: 0.5em;
    color: #FFF;
    font-size: 1.875rem;
  }
}

.top .shigikai__policy .policy__head p {
  font-weight: 700;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__policy .policy__head p {
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__policy .policy__head p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy .policy__head p {
    color: #FFF;
    font-size: 1.125rem;
  }
}

.top .shigikai__policy .policy__main {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__policy .policy__main {
    width: 1200px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__policy .policy__main {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy .policy__main {
    flex-direction: column;
    margin: 0 10px;
    padding: 20px 10px;
    background-color: #FFF;
  }
}

@media screen and (min-width: 1124px) {
  .top .shigikai__policy .policy__list {
    margin-right: 60px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__policy .policy__list {
    margin-right: 60px;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy .policy__list {
    width: 100%;
    background-color: #FFF;
  }
}

.top .shigikai__policy .policy__list h4 {
  margin-bottom: 20px;
  font-weight: 700;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__policy .policy__list h4 {
    font-size: 2.875rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__policy .policy__list h4 {
    font-size: 2.375rem;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy .policy__list h4 {
    text-align: center;
    font-size: 1.875rem;
  }
}

.top .shigikai__policy .policy__list ol {
  margin-left: 30px;
  counter-reset: li_count;
}

.top .shigikai__policy .policy__list ol > li {
  position: relative;
  margin-bottom: 40px;
}

.top .shigikai__policy .policy__list ol > li:before {
  position: absolute;
  top: -8px;
  left: 0;
  margin-left: -1.8rem;
  font-weight: 700;
  font-size: 2rem;
  counter-increment: li_count;
  content: counter(li_count);
  color: #0087CB;
}

.top .shigikai__policy .policy__list ol > li h5 {
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 1.3125rem;
}

.top .shigikai__policy .policy__list ol > li ul {
  counter-reset: li_count;
  list-style-type: disc;
}

.top .shigikai__policy .policy__list ol > li ul li {
  margin-bottom: 0.5em;
  margin-left: 1.5em;
  font-size: 0.875rem;
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy .policy__list ol > li ul li {
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy .policy-map {
    display: none;
  }
}

@media screen and (min-width: 1124px) {
  .top .shigikai__policy .assistant {
    position: absolute;
    bottom: 220px;
    left: 50%;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__policy .assistant {
    position: absolute;
    bottom: 130px;
    right: 5%;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__policy .assistant {
    width: 100%;
  }
}

.top .shigikai__policy .assistant a {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px 0 15px 0;
  box-shadow: 8px 8px 6px 0px rgba(0, 0, 0, 0.16);
  text-align: center;
  color: #FFF;
  background-color: #0087CB;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__policy .assistant a {
    width: 390px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__policy .assistant a {
    width: 390px;
  }
}

.top .shigikai__policy .assistant a:hover {
  opacity: 0.75;
}

.top .shigikai__policy .assistant a h5 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 1.4375rem;
}

.top .shigikai__policy .assistant a h5 .mdi {
  margin-top: 2px;
  margin-right: 7px;
  font-size: 1.875rem;
}

.top .shigikai__policy .assistant a p {
  font-size: 1.25rem;
}

.top .shigikai__policy .assistant a p .mdi {
  margin-right: 8px;
  font-size: 1.75rem;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__activity {
    margin-top: -50px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__activity {
    margin-top: -50px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__activity {
    padding: 0 10px 40px 10px;
    background-color: #0087CB;
  }
}

.top .shigikai__activity h3 {
  margin-bottom: 0.6em;
  font-weight: 700;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__activity h3 {
    color: #333;
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__activity h3 {
    color: #333;
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__activity h3 {
    color: #FFF;
    font-size: 2.5rem;
  }
}

.top .shigikai__activity .activity__inner {
  padding: 40px;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__activity .activity__inner {
    background-color: #0087CB;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__activity .activity__inner {
    background-color: #0087CB;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__activity .activity__inner {
    background-color: #FFF;
  }
}

.top .shigikai__activity .activity__inner ul li {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  text-align: left;
}

.top .shigikai__activity .activity__inner ul li time {
  display: block;
  margin-bottom: 10px;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__activity .activity__inner ul li time {
    color: #FFF;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__activity .activity__inner ul li time {
    color: #FFF;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__activity .activity__inner ul li time {
    color: #333;
  }
}

.top .shigikai__activity .activity__inner ul li .slide-img {
  overflow: hidden;
  max-height: 200px;
  margin-bottom: 10px;
}

.top .shigikai__activity .activity__inner ul li img {
  width: 100%;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__activity .activity__inner ul li img {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__activity .activity__inner ul li img {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__activity .activity__inner ul li img {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1124px) {
  .top .shigikai__activity .activity__inner ul li h4 {
    color: #FFF;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__activity .activity__inner ul li h4 {
    color: #FFF;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__activity .activity__inner ul li h4 {
    color: #333;
  }
}

.top .shigikai .swiper-button-prev:after,
.top .shigikai .swiper-button-next:after {
  font-size: 40px;
  font-weight: 900;
  color: #FFF;
}

.top .shigikai .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: .5;
}

.top .shigikai .swiper-pagination-bullet-active {
  background-color: #FFF;
  opacity: 1;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__access {
    margin-bottom: 60px;
    color: #333;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__access {
    margin-bottom: 60px;
    color: #333;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__access {
    margin-bottom: 20px;
    color: #FFF;
    background-color: #0087CB;
  }
}

.top .shigikai__access h3 {
  margin-bottom: 0.5em;
  font-weight: 700;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__access h3 {
    font-size: 2.125rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__access h3 {
    font-size: 2.125rem;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__access h3 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1124px) {
  .top .shigikai__access p {
    margin-bottom: 0.5em;
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__access p {
    margin-bottom: 0.5em;
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__access p {
    margin-bottom: 0.3em;
  }
}

.top .shigikai__access .map {
  padding: 10px;
}

@media screen and (min-width: 1124px) {
  .top .shigikai__access .map iframe {
    height: 460px;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .top .shigikai__access .map iframe {
    height: 460px;
  }
}

@media screen and (max-width: 768px) {
  .top .shigikai__access .map iframe {
    height: 230px;
  }
}

.footer {
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (min-width: 1124px) {
  .footer {
    font-size: 1.0625rem;
  }
}

@media screen and (max-width: 1123px) and (min-width: 769px) {
  .footer {
    font-size: 1.0625rem;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding-top: 20px;
    font-size: 0.75rem;
  }
}
