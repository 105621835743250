// レイアウト幅
$layout-width-inner: 1200px;

// ブレークポイント
$breakpoints: (
  "sp": "screen and (max-width: 768px)",
  "sp-tb": "screen and (max-width: 768px) and (min-width: 481px)",
  "tb": "screen and (max-width: 1123px) and (min-width: 769px) ",
  "pc": "screen and (min-width: 1124px)",
);

//画像格納ディレクトリ
$img-dir: '/img/';

//サイトで使用するカラー
$colors: (
  main: #333,
  gyosei: #996154,
  shigikai: #0087CB,
);