@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://cdn.materialdesignicons.com/4.7.95/css/materialdesignicons.min.css');

html {
  height: 100%;
  text-rendering: optimizeLegibility;
  font-size: 16px;
}

body {
  color: #333;
  font-family: "Noto Sans JP", san-serif;
}

a {
  text-decoration: none;
  // transition: all 0.3s;
  color: #333;
  &:hover {
    text-decoration: none;
  }
}

p {
  line-height: 1.6;
}

// スクロール
* {
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(204, 204, 204, 0.5);
  }
  &::-webkit-scrollbar-thumb {
    background-color: map-get($colors, "main");
  }
}
body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

@include mq('sp') {
  *, *:before, *:after {
    box-sizing: border-box;
  }
  img {
    max-width: 100%;
    height: auto;
    width /***/:auto;
  }
  
}

